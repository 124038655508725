.container {
  position: fixed;
  background-color: white;
  display: flex;
  justify-content: center;
  background-color: gray;
  align-items: center;

  bottom: 1rem;
  right: 1rem;
  background-color: transparent;
  background-color: white;
  padding: 0.5rem;
  border-radius: 50px;
  border: 1px solid #bbb;
}

.container2{
  right: 6rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  border-image-slice: 1;
  padding: 0.5rem;
}

.content p {
  color: rgb(17, 140, 1);
  font-weight: 700;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  width: fit-content;
}

@media only screen and (max-width: 800px) {
  .content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    pointer-events: all;
  }
}
