.container {
  color: #242441;
  width: 100%;
}

/* Services */
.services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.service {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.2rem;
  padding-right: 0.21rem;

  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 12rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
}
.service h3 {
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;
  font-weight: 700 !important;
  font-size: 0.9rem;
  color: rgb(68, 68, 68);
}

/* Quality */
.quality {
  width: 100%;
}

.qualityInfos {
}

.qualityInfo {
}


/* For Mobile Phones */

@media only screen and (max-width: 600px) {
  .services {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .service {
    width: 50%;
    padding: 0 !important;
    height: auto;
  }
  .service h3 {
    font-size: 0.8rem;
  }
  .service svg {
    width: 2rem;
  }
}