.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  background-color: #f5f5f5;
}

/* Hizmetlerimiz */
.services_section {
  margin-top: 1rem;
  width: 100%;
}
.services {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services_title {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
  animation: fadeIn 1s ease-in-out;
}

.services_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.services_service {
  color: black;
  margin: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding: 1rem;
  width: 15rem;

  border: 1px solid gray;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, rgba(207, 0, 0, 0.761), gray);
  background-color: white;
  box-shadow: 0px 0px 4px gray;
  animation: fadeIn 1s ease-in-out;
}

.services_service_header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.services_service_logo_area {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  border: 1px solid gray;
  padding-bottom: 0.2rem;
  margin-bottom: 0.4rem;
  border-image-slice: 1;
  border-width: 0;
  border-bottom-width: 1px;
  border-image-source: linear-gradient(to left, #ffffff, gray);
}

.services_service_title {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.services_service_details {
  padding: 0.2rem 0.5rem;
  color: #cf0000;
  border-bottom: 0.1px solid #cf0000;
  margin-top: 1rem;
}

@media only screen and (max-width: 800px) {
  .services_title {
    width: 100%;
    text-align: center;
    margin-top: 1.2rem;
    margin-bottom: 1rem;
  }

  .services_service_title {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  .services_service {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 11rem;
  }
}

/* Effects */
@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 5rem;
  }
  100% {
    opacity: 100%;
  }
}
