.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #242441;
}

/* Contact Bar */
.contactBar_container {
  width: 100%;
}

.contactBar_container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;

  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  list-style: none;
}
.contactBar_container ul li {
}
.contactBar_container ul li a {
  padding-bottom: 0.3rem;
  color: rgb(188, 8, 8);
  font-weight: 600;
  border: 0px solid rgb(169, 169, 169);
  border-image-slice: 1;
  border-bottom-width: 1px;
  border-image-source: linear-gradient(to right, #9a0000);
}
/* Question's */

.container_1 {
  width: 70%;
}

.container_question {
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  border: 0.5px solid black;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #000000, rgb(202, 202, 202));
}

.container_question p {
  margin-top: 0.5rem;
  font-weight: 500;
  text-align: left;
  font-size: 0.9rem;
  color: rgb(36, 36, 65);
}

.reach_us_link {
  color: rgb(188, 8, 8);
  padding-bottom: 0.3rem;
  border: 0px solid gray;
  border-image-slice: 1;
  border-bottom-width: 1px;
  border-image-source: linear-gradient(
    to right,
    #9a0000,
    #f5f5f5,
    #f5f5f5,
    #f5f5f5
  );
}

@media only screen and (max-width: 800px) {
  .container_1 {
    width: 95%;
  }
  .container_1 p {
    font-weight: 500;
    text-align: left;
    font-size: 0.8rem;
  }
}

/* Our Works */

.ourWorks_container {
  text-align: center;
  width: 70%;
}

.ourWorks_container h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: rgb(36, 36, 65);
  padding-bottom: 1rem;
  border-bottom: 2px solid rgb(36, 36, 65);
}

.ourWorks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: auto;
}

.ourWork {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  margin: 0.5rem;
  overflow: hidden;
  box-shadow: 0px 0px 4px gray;
}

.ourWork img {
  height: 100%;
}
.ourWork p {
  font-size: 0.9rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: auto;
  display: none;


}

@media only screen and (max-width: 800px) {
  .ourWorks_container {
    width: 95%;
  }
  .ourWork {
    width: 40%;
  }
  .ourWork img {
  }
}

/* Packages */

.packages_container {
  text-align: center;
  width: 70%;
}
.packages_title {
  width: 100%;
}

.packages_container h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 1.3rem;
  color: rgb(36, 36, 65);
  padding-bottom: 1rem;
  border-bottom: 2px solid rgb(36, 36, 65);
}

.packages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
}

.package {
  display: flex;
  flex-direction: column;

  width: 18rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 4px gray;
  border-radius: 12px;
  border: 1px solid #9a0000;
  padding-bottom: 0.5rem;
}

.package_title {
  font-weight: 700 !important;
  letter-spacing: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.1rem;
  width: 75%;
  margin: auto;
  color: #2f2f2f;
  text-align: center;
  border-bottom: 1px solid gray;
}

.package_content {
  text-align: left;
  margin: auto;
  margin-top: 1rem;
  width: 70%;
}

.package_content ul {
  list-style: disc;
}

.package_content ul li {
  font-weight: 500;
  margin-top: 0.5rem;
}

.package_price {
  margin: auto;
  margin-top: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  width: fit-content;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.2rem;

  color: rgb(210, 5, 5);
}

.package_contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  box-shadow: 0px 0px 2px gray;
  border-radius: 4px;
  width: fit-content;
  margin: auto;
}
.package_contact p {
  margin-left: 0.5rem;
  color: rgb(13, 154, 0);
}

@media only screen and (max-width: 800px) {
  .packages_container {
    width: 95%;
  }

  .campaign_container a {
    font-size: 0.8rem;
  }
}

.campaign_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.campaign_container a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 8px;

  color: white;
  background-color: rgb(234, 0, 0);

  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;

  transition: all 0.1s ease-in-out;
}
.campaign_container a:hover {
  box-shadow: 0px 0px 15px 0px rgba(100, 0, 0, 0.75);
}

.campaign_container a svg {
  margin-left: 0.5rem;
}

.campaign_container ul li {
  list-style-type: none;
  margin-bottom: 0.5rem;
  margin: auto !important;
}

.campaign_container h2 {
  color: red;
  margin-bottom: 1rem;
}

.campaign_container p {
  color: #242441;
  margin-bottom: 1rem;
}
