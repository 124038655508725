@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}
* {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
    overflow-x: hidden;
    position: relative;
}

body {
  background-color: #f5f5f5;
}

h1 {
  font-size: 1.6rem;
  font-weight: 700 !important;
}

h2 {
  font-size: 1.3rem;
  font-weight: 600 !important;
}
h3 {
  font-size: 1.2rem;
  font-weight: 400 !important;
}

@media only screen and (max-width: 1100px) {
  h1 {
    font-size: 1.5rem;
    font-weight: 700 !important;
  }
  h2 {
    font-size: 1.2rem;
    font-weight: 600 !important;
  }
  h3 {
    font-size: 1rem;
    font-weight: 400 !important;
  }
}

@media only screen and (max-width: 780px) {
  h1 {
    font-size: 1.2rem;
    font-weight: 700 !important;
  }
  h2 {
    font-size: 1.1rem;
    font-weight: 600 !important;
  }
  h3 {
    font-size: 1rem;
    font-weight: 400 !important;
  }
}

a,
a:hover {
  text-decoration: none;
}

input:not([type="button"]):not([type="submit"]):not([type="reset"]):hover,
input:not([type="button"]):not([type="submit"]):not([type="reset"]):focus,
textarea:hover,
textarea:focus,
select:hover,
select:focus {
  border-bottom: 1px solid #cf0000;
  color: #cf0000;
  font-weight: 600;

  outline: none;
  transition: border-bottom 0.3s ease;
}

/* ScrollBar */

/* Firefox */
* {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #3d0000 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #3d0000;
  border-radius: 6px;
  border: 3px solid #ffffff;
}

ul {
  list-style: none;
}
