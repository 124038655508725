.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;

  margin-top: 2rem;
  width: 100%;
}
.contact_method {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  min-width: 16rem;

  border: 0.5px solid black;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #000000, rgb(202, 202, 202));

  box-shadow: 0px 0px 15px rgb(145, 144, 144);
  animation: fadeIn 0.8s ease-in-out;
}

.logo_area {
  color: black;
}

.contact_title {
  color: black;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
.contact_info {
  color: rgb(52, 52, 52);
}

/* Effects */
@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 5rem;
  }
  150% {
    opacity: 100%;
    margin-top: 0rem;
  }
}
