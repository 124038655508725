.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;

  width: 100%;
  padding-top: 0rem;
  padding-bottom: 2rem;
  animation: fadeIn 0.8s ease-in-out;
}

.widget {
  padding: 1rem;
  padding-left: 0 !important;
  width: 30%;
  margin-top: 1rem;
  margin: auto;
}

.widget_title {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  color: whitesmoke;

  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #dedede94,
    rgba(255, 255, 255, 0)
  );
}

@media only screen and (max-width: 900px) {
  .widget {
    width: 95%;
  }
  .widget_title {
    font-size: 1.08rem;
    text-align: center;
  }
}

/* Widget Content */

.widget_content {
  margin: auto;
}

/* Subpage Style */

.subpage_style_container {
  display: flex;
  flex-direction: column;
}

/* Services */

.services_ul {
  list-style: none;
  color: gray;
}
.services_ul li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(105, 105, 105);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;

  border-bottom: 1px solid gray;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #888888, gray);

  transition: all 0.3s ease-in-out;
}
.services_link {
  font-size: 1rem;
  font-weight: 300;
  color: rgb(255, 255, 255);
}

.services_link_subpage {
  padding-left: 2rem;
  font-size: 1rem;
  font-weight: 300;
  color: rgb(255, 255, 255);
}

.services_ul li:hover {
  background-color: rgba(128, 128, 128, 0.522);
  border-bottom: 1px solid gray;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #ffffff, rgb(255, 255, 255));
}

/* Contact */
.contact_ul {
  list-style: none;
  color: gray;
}

.contact_ul li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid rgb(105, 105, 105);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;

  border-bottom: 1px solid gray;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #888888, gray);

  transition: all 0.3s ease-in-out;
}

.contact_ul li .contact_content_area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.contact_ul li p {
  margin-left: 0.5rem;
}

.contact_link {
  font-size: 1rem;
  font-weight: 300;
  color: rgb(255, 255, 255);
}

.forwardIcon {
  right: 0;
}

/* Effects */
@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 5rem;
  }
  100% {
    opacity: 100%;
    margin-top: 0rem;
  }
}
