/* Teklif Alin Form */

.wrapper_form {
  display: flex;
  justify-content: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: white;
  width: 30rem;
  height: 90%;
  transition: background-color 0.5s;
  animation: fadeIn 0.1s ease-in-out;
  box-shadow: 0px 0px 15px rgb(145, 144, 144);
  border-bottom: 5px solid #cf0000;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.contactForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.contactForm input {
  width: 85%;
  height: 2rem;

  padding: 0.7rem;
  margin-top: 1rem;

  font-size: 0.9rem;
  font-weight: 600;

  border: none;
  border-bottom: 1px solid gray;
}

.contactForm button {
  cursor: pointer;

  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 1px;
  width: 85%;
  height: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: black;

  background-color: white;

  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to left, #cf0000, gray);

  transition: all 0.3s ease-in-out;
}

.contactForm button:hover {
  background-color: #cf0000;
  color: white;
  border: 1px solid #cf0000;
  box-shadow: 0px 0px 10px #cf0000;
}

.title {
  padding: 2rem;
  font-size: 1.5rem;
}

.submitFormSuccess {
  margin-bottom: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding-bottom: 5px;
  color: #6e0202;
  border-bottom: 1px solid #cf0000;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 5rem;
  }
  150% {
    opacity: 100%;
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 768px) {
  .wrapper_form {
    width: 80%;
  }
}
