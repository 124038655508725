.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
}

.no_page_link_container {
  display: flex;
  flex-direction: row;
  margin: 2rem;
  width: 75%;
}

.no_page_title {
  text-align: center;
  font-size: 1.8rem;
  width: 80%;
}
.no_page_link {
  font-weight: 500;
  text-align: center;
  width: 75%;
  margin: 1rem;
  padding: 1rem;

  color: black;
  border: 1px solid gray;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to left, #cf0000, gray);

  transition: all 0.3s ease-in-out;
}

.no_page_link:hover {
  background-color: #cf0000;
  color: white;
  border: 1px solid #cf0000;
  box-shadow: 0px 0px 10px #cf0000;
}

@media only screen and (max-width: 1000px) {
  .no_page_link_container {
    display: flex;
    flex-direction: row;
    margin: 2rem;
    width: 100%;
  }
}
