.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
}

.about_us {
  font-weight: 600;
  font-size: 0.95rem;
  color: rgb(36, 36, 65);
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  animation: fadeIn 0.8s ease-in-out;
}

@media only screen and (max-width: 800px) {
  .about_us {
    width: 90%;
  }
}

/* Effects */
@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 5rem;
  }
  150% {
    opacity: 100%;
    margin-top: 0rem;
  }
}
