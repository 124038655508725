ul {
  list-style: decimal;
  margin-left: 2rem;
}
li {
  margin-bottom: 0.5rem;
}

.content_image_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contentImage {
  padding: 0.1rem;
  height: 25vh;
  max-width: 33%;
  object-fit: cover;
  object-position: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #242441;
}

/* Contact Bar */
.contactBar_container {
  width: 100%;
}

.contactBar_container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;

  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  list-style: none;
}
.contactBar_container ul li {
}
.contactBar_container ul li a {
  padding-bottom: 0.3rem;
  color: rgb(188, 8, 8);
  font-weight: 600;
  border: 0px solid rgb(169, 169, 169);
  border-image-slice: 1;
  border-bottom-width: 1px;
  border-image-source: linear-gradient(to right, #9a0000);
}
/* Question's */

.container_1 {
  width: 70%;
}

.container_question {
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  border: 0.5px solid black;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #000000, rgb(202, 202, 202));
}

.container_question p {
  margin-top: 0.5rem;
  font-weight: 500;
  text-align: left;
  font-size: 0.9rem;
  color: rgb(36, 36, 65);
}

.reach_us_link {
  color: rgb(188, 8, 8);
  padding-bottom: 0.3rem;
  border: 0px solid gray;
  border-image-slice: 1;
  border-bottom-width: 1px;
  border-image-source: linear-gradient(
    to right,
    #9a0000,
    #f5f5f5,
    #f5f5f5,
    #f5f5f5
  );
}

@media only screen and (max-width: 800px) {
  .container_1 {
    width: 95%;
  }
  .container_1 p {
    font-weight: 500;
    text-align: left;
    font-size: 0.8rem;
  }
  .campaign_container a {
    font-size: 0.8rem;
  }
}

.campaign_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.campaign_container a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 8px;

  color: white;
  background-color: rgb(234, 0, 0);

  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 0.6rem;

  transition: all 0.1s ease-in-out;
}

.campaign_container a:hover {
  box-shadow: 0px 0px 15px 0px rgba(100, 0, 0, 0.75);
}

.campaign_container a svg {
  margin-left: 0.5rem;
}

.campaign_container ul li {
  list-style-type: none;
  margin-bottom: 0.5rem;
  margin: auto !important;
}

.campaign_container h2 {
  color: red;
  margin-bottom: 1rem;
}

.campaign_container p {
  color: #242441;
  margin-bottom: 1rem;
}
