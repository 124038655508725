.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #242441;
}

/* Contact Bar */
.contactBar_container {
  width: 100%;
}

.contactBar_container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;

  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  list-style: none;
}
.contactBar_container ul li {
}
.contactBar_container ul li a {
  padding-bottom: 0.3rem;
  color: rgb(188, 8, 8);
  font-weight: 600;
  border: 0px solid rgb(169, 169, 169);
  border-image-slice: 1;
  border-bottom-width: 1px;
  border-image-source: linear-gradient(to right, #9a0000);
}
/* Question's */

.container_1 {
  width: 70%;
}

.container_question {
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  border: 0.5px solid black;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #000000, rgb(202, 202, 202));
}

.container_question p {
  margin-top: 0.5rem;
  font-weight: 500;
  text-align: left;
  font-size: 0.9rem;
  color: rgb(36, 36, 65);
}

.reach_us_link {
  color: rgb(188, 8, 8);
  padding-bottom: 0.3rem;
  border: 0px solid gray;
  border-image-slice: 1;
  border-bottom-width: 1px;
  border-image-source: linear-gradient(
    to right,
    #9a0000,
    #f5f5f5,
    #f5f5f5,
    #f5f5f5
  );
}

@media only screen and (max-width: 800px) {
  .container_1 {
    width: 95%;
  }
  .container_1 p {
    font-weight: 500;
    text-align: left;
    font-size: 0.8rem;
  }
}
