/* Navbar */

.navbar {
  display: flex;
  margin: auto;
  background-color: white;
  width: 100%;

  height: 4.5rem;
  box-shadow: 0px 0px 3px rgb(129, 129, 129);
}

.navbar_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 90%;
  margin: auto;
  height: 100%;
}
.navbar_logo {
  display: flex;
  height: 100%;
}
.navbar_logo a {
  display: flex;
}

.navbar_logo a img {
  margin: auto;
  width: auto;
  height: 2.9rem;
}

.navbar_menu {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 5rem;
  width: 3.5rem;
  height: 3.5rem;

  cursor: pointer;
  border: none;

  background-color: white;
}

@media only screen and (max-width: 768px) {
  .navbar_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 1.2rem;
    width: 2.5rem;
    height: 2.5rem;

    cursor: pointer;
    border: none;

    background-color: white;
  }
  .navbar_logo a img {
    margin: auto;
    width: auto;
    height: 2rem;
  }
}

/* Menu  */

.menu {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  transition: background-color 0.5s;
  animation: fadeIn 0.3s ease-in-out;
}

.menu_wrapper {
  position: relative;

  display: flex;
  justify-content: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;

  width: 30rem;
  height: 90%;

  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-left: 5px solid black;

  background-color: white;
  transition: background-color 0.5s;
  animation: fadeIn 0.1s ease-in-out;
  box-shadow: 0px 0px 15px rgb(145, 144, 144);
  overflow-y: auto;
}

.menu_close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;

  right: 0rem;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: 2rem;
  margin-right: 3rem;

  cursor: pointer;
  border: none;

  background-color: white;
}

.menu_title {
  padding: 2rem;
  font-size: 2rem;
}

.menu_wrapper ul {
  position: relative;
  align-items: center;
  justify-content: center;
  list-style: circle;
  width: 70%;
}
.menu_wrapper ul a {
  color: black;
}

.menu_wrapper ul a li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 300;
  font-size: 1.2rem;
  padding: 0.5rem;
  margin-bottom: 1rem;

  border-bottom: 0.5px solid black;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #cf0000, gray);

  letter-spacing: normal;

  transition: all 0.3s ease-in-out;
}

.menu_wrapper ul a li:hover {
  letter-spacing: 0.3px;
  border-bottom: 1.5px solid black;
  border-image-source: linear-gradient(to left, #000000, rgb(0, 0, 0));
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .menu {
    width: 100%;
  }

  .menu_wrapper {
    align-items: flex-start;
    height: 100%;
    width: 100%;

    border-radius: 0px;
  }

  .menu_wrapper ul {
    margin-left: 2rem;
  }
}

/* Teklif Alin Form */

.menu_wrapper_form {
  position: relative;
  display: flex;
  justify-content: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;

  background-color: white;
  width: 30rem;
  height: 90%;
  transition: background-color 0.5s;
  animation: fadeIn 0.1s ease-in-out;
  box-shadow: 0px 0px 15px rgb(145, 144, 144);
  border-bottom: 5px solid #cf0000;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.contactForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.contactForm input {
  width: 85%;
  height: 2rem;

  padding: 0.7rem;
  margin-top: 1rem;

  font-size: 1rem;
  font-weight: 600;

  border: none;
  border-bottom: 1px solid gray;
}

.contactForm button {
  cursor: pointer;

  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 1px;
  width: 85%;
  height: 2rem;
  margin-top: 1rem;

  background-color: white;

  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(to left, #cf0000, gray);

  transition: all 0.3s ease-in-out;
}

.contactForm button:hover {
  background-color: #cf0000;
  color: white;
  border: 1px solid #cf0000;
  box-shadow: 0px 0px 10px #cf0000;
}

.submitFormSuccess {
  font-weight: 600;
  letter-spacing: 1px;
  padding-bottom: 5px;
  color: #6e0202;
  border-bottom: 1px solid #cf0000;
}

@media only screen and (max-width: 1000px) {
  /* For mobile phones: */

  .menu_wrapper_form {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 5rem;
  }
  150% {
    opacity: 100%;
    margin-top: 0rem;
  }
}

/* Menu Footer */

.menu_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  bottom: 0px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Subpage Support */

.subpage li {
  padding-top: 0rem !important;
  margin-left: auto;
  width: 94% !important;
}
