/* Title Area */
.titleArea {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 8rem;
  color: rgb(255, 255, 255);

  box-shadow: inset 0px 35px 125px rgb(0, 0, 0);
  animation: fadeIn 0.8s ease-in-out;
}

.titleArea h1 {
  text-align: center;
  width: 100%;
  animation: fadeIn 1.2s ease-in-out;
}

.titleArea p {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 800px) {
  .titleArea {
    height: 5rem;
  }
}

/* Effects */
@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 5rem;
  }
  100% {
    opacity: 100%;
    margin-top: 0rem;
  }
}
